import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const JourneyBuilderSettings = lazy(
  () => import('../components/JourneyBuilderSettings')
);

const journeyBuilderSettings: RouteObject = {
  path: 'settings/modules/journey-builder',
  element: <JourneyBuilderSettings />,
};

export default journeyBuilderSettings;
