import { Box, FormControl, InputLabel, Popover, Select } from "@mui/material";
import { useState } from "react";

import useUser from "../../../../hooks/useUser";
import styles from "./ProductHierarchyConfig.module.scss";
import HierarchySelect from "../../../../shared/components/DashboardFilters/Filters/ProductSelect/HierarchySelect/HierarchySelect";

interface ProductHierarchyConfigProps {
  selectedHierarchy: string[];
  setSelectedHierarchy: (value: string[]) => void;
  disableHierarchySelection?: boolean;
}

const ProductHierarchyConfig = ({
  setSelectedHierarchy,
  selectedHierarchy,
  disableHierarchySelection = false,
}: ProductHierarchyConfigProps) => {
  const { selectedBusiness } = useUser();
  const productHierarchyValues = selectedBusiness.productHierarchy.map(
    ({ value }) => value
  );

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.SyntheticEvent<Element, Event>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getRenderValue = () => {
    if (selectedHierarchy.length === 0) return null;
    return selectedBusiness.productHierarchy
      .filter(({ value }) => selectedHierarchy.includes(value))
      .map(({ label }) => label)
      .join(", ");
  };

  const handleHierarchyChange = (hierarchy: string[]) => {
    setSelectedHierarchy(hierarchy);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>Hierarchy</InputLabel>
        <Select
          value={selectedHierarchy}
          label="Hierarchy"
          displayEmpty
          open={false}
          onOpen={handleOpen}
          onClose={handleClose}
          renderValue={getRenderValue}
        />
      </FormControl>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        role="menu"
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            id: styles.popover,
          },
        }}
      >
        <HierarchySelect
          options={productHierarchyValues}
          value={selectedHierarchy}
          onChange={handleHierarchyChange}
          disableSelection={disableHierarchySelection}
        />
      </Popover>
    </Box>
  );
};

export default ProductHierarchyConfig;
