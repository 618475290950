import { Controller, useForm } from "react-hook-form";
import { Box, Card, Paper, TextField } from "@mui/material";
import styles from "./CustomerAnalysisPeriodForm.module.scss";
import Header from "../../../../shared/components/Header/Header";
import LoadingButton from "../../../../shared/components/LoadingButton/LoadingButton";
import { MemberReportConfigType } from "../../interfaces";
import CaseConfigLoader from "../CaseConfig/CaseConfigLoader";
import { useEffect } from "react";

interface CustomerAnalysisPeriodFormProps {
  title: string;
  description: string;
  data: MemberReportConfigType | null;
  setData: (data: MemberReportConfigType) => Promise<void>;
  isLoading: boolean;
}

export default function CustomerAnalysisPeriodForm({
  title,
  description,
  data,
  setData,
  isLoading,
}: CustomerAnalysisPeriodFormProps) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, isSubmitting },
  } = useForm({
    mode: "all",
    defaultValues: {
      analysisPeriod: data?.analysisPeriod,
    },
  });

  const onSubmit = async (formData: MemberReportConfigType) => {
    await setData(formData).then(() => reset(formData));
  };
  useEffect(() => {
    reset({
      analysisPeriod: data ? data?.analysisPeriod : undefined,
    });
  }, [data, reset]);
  return (
    <Paper
      component="form"
      elevation={0}
      id={styles.MemberConfig}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box className={styles.header}>
        <Header title={title} variant={"h6"} description={description}></Header>
        <Box className={styles.saveButtonContainer}>
          {!isLoading && (
            <LoadingButton
              // sx={{ mt: 2 }}
              loading={isSubmitting}
              disabled={!isDirty}
              variant="contained"
              type="submit"
            >
              Save configuration
            </LoadingButton>
          )}
        </Box>
      </Box>
      {!isLoading ? (
        <Card className={styles.memberCard}>
          <Box className={styles.memberColumns}>
            <Controller
              name="analysisPeriod"
              control={control}
              rules={{
                required: "Please enter a value between 1 and 24 ",
                min: { value: 1, message: "Minimum value is 1" },
                max: { value: 24, message: "Maximum value is 24" },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  label="Analysis Period (in months)"
                  type="number"
                  value={value}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      onChange(inputValue);
                    }
                  }}
                  error={!!error}
                  helperText={
                    error ? error.message : "Enter a value between 1 and 24"
                  }
                  inputProps={{ min: 1, max: 24, step: 1 }}
                  fullWidth
                />
              )}
            />
          </Box>
        </Card>
      ) : (
        <CaseConfigLoader />
      )}
    </Paper>
  );
}
