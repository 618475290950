import { lazy } from "react";
import { RouteObject } from "react-router-dom";

const IntellicloseConfig = lazy(
  () => import("../components/IntellicloseConfig")
);
const LeadCreationConfig = lazy(
  () => import("../components/LeadCreationConfig/LeadCreationConfig")
);
const LeadManageConfig = lazy(
  () => import("../components/LeadManageConfig/LeadManageConfig")
);
const LeadReportConfig = lazy(
  () => import("../components/LeadReportConfig/LeadReportConfig")
);
const LeadTargetConfig = lazy(
  () => import("../components/LeadTargetConfig/LeadTargetConfig")
);

const intellicloseConfigRoute: RouteObject = {
  path: "settings/modules/intelliclose",
  element: <IntellicloseConfig />,
  children: [
    {
      index: true,
      element: <LeadCreationConfig />,
    },
    {
      path: "manage-config",
      element: <LeadManageConfig />,
    },
    {
      path: "report-config",
      element: <LeadReportConfig />,
    },
    {
      path: "target-config",
      element: <LeadTargetConfig />,
    },
  ],
};

export default intellicloseConfigRoute;
